<template scoped>
  <div>
    <el-form-item
      :rules="rules.command"
      label="Command"
      prop="send.command"
      :style="{ marginTop: '10px' }"
    >
      <el-input v-model="logic.send.command" placeholder="">
        <el-switch
          slot="append"
          v-model="logic.send.commandValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.checksumEnable"
      label="Checksum"
      prop="send.checksum.enable"
      :style="{ marginTop: '10px' }"
    >
      <el-switch
        v-model="logic.send.checksum.enable"
        active-color="#13ce66"
        inactive-color="#ff4949"
      >
      </el-switch>
    </el-form-item>

    <el-form-item
      labelWidth="150px"
      v-if="logic.send.checksum.enable"
      :rules="rules.checksumMethodType"
      label="Checksum Method"
      prop="send.checksum.type"
      :style="{ marginTop: '10px',marginLeft:'180px' }"
    >
      <el-select
        :disabled="!logic.send.checksum.enable"
        v-model="logic.send.checksum.type"
        :style="{ width: '100%' }"
        placeholder="Select the Method"
      >
        <el-option
          v-for="item in supportChecksumMethod"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>
      
<script scoped>
export default {
  props: {
    logic: Object,
  },
  methods: {
    validator_command: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the command should not be empty"));
      } else {
        callback();
      }
    },
    validator_checksumEnable: function (rule, value, callback) {
      callback();
    },
  },
  data: function () {
    return {
      supportChecksumMethod: [
        {
          name: "CheckSum8 Xor",
          value: "CheckSum8Xor",
        },
        {
          name: "CheckSum8 Modulo 256",
          value: "CheckSum8Modulo256",
        },
        {
          name: "CheckSum8 2s Complement",
          value: "CheckSum82sComplement",
        },
        {
          name: "CRC-16",
          value: "CRC-16",
        },
        {
          name: "CRC-16-CCITT",
          value: "CRC-16-CCITT",
        },
        {
          name: "CRC-16-IBM",
          value: "CRC-16-IBM",
        },
        {
          name: "CRC-16-T10-DIF",
          value: "CRC-16-T10-DIF",
        },
        {
          name: "CRC-16-DNP",
          value: "CRC-16-DNP",
        },
        {
          name: "CRC-16-DECT",
          value: "CRC-16-DECT",
        },
        {
          name: "CRC-32",
          value: "CRC-32",
        },
        {
          name: "CRC-32C (Castagnoli)",
          value: "CRC-32C (Castagnoli)",
        },
        {
          name: "CRC-32K (Koopman)",
          value: "CRC-32K (Koopman)",
        },
        {
          name: "CRC-32Q",
          value: "CRC-32Q",
        },
      ],
      rules: {
        command: [{ validator: this.validator_command, trigger: "blur" }],
        checksumEnable: [
          { validator: this.validator_checksumEnable, trigger: "blur" },
        ],
      },
    };
  },
};
</script>    